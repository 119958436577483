import { useState, useEffect, useReducer, useMemo } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, Button, Grid, Typography } from "@mui/material";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import subDays from "date-fns/subDays";
import { useSelector } from "react-redux";

import { ISchedule, IScheduleMany } from "../../types/schedule";
import { selectUser } from "../../features/auth/authSlice";
import { IProjectAccess } from "../../types/user";
import { convertAllDates, timeZoneDate, downloadExcel, downloadPdf, urlName } from "../../utils/function";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import AppointmentsTable from "./AppointmentsTable";
import BookAnAppointmentDialog from "../registrant/process/BookAnAppointmentDialog";
import { handleModal } from "../../features/modal/modalSlice";
import { GlobalModal } from "../../features/modal/Modal";
import MassMail from "../common/MassMail";
import MassText from "../common/MassText";
import { IAppointment } from "../../types/project";
import StandardTable from "../tables/StandardTable";
import { FlexBetween } from "../../commonStyles";

export const appointmentsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD":
      return [...state, action.payload.value];
    case "COPY":
      return [...state, action.payload];
    case "UPDATE":
      return state.map((state: any, index: number) => {
        if (state._id === action.payload._id) {
          return {
            ...state,
            [action.payload.field]: action.payload.value,
          };
        } else return state;
      });
    case "UPDATEALL":
      return action.payload;
    case "DELETE":
      return state.filter((state: any, index: number) => state._id !== action.payload._id);
    default:
      throw new Error();
  }
};

const Events = () => {
  const storeDispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const [schedule, setSchedule] = useState<ISchedule | null>(null);
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [archivedSchedules, setArchivedSchedules] = useState<ISchedule[]>([]);
  const [archivedSchedule, setArchivedSchedule] = useState<ISchedule | null>(null);
  const [appointmentsState, appointmentsDispatch] = useReducer(appointmentsReducer, []);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);
  const [dialogType, setDialogType] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>("");
  const [bookAppointmentOpen, setBookAppointmentOpen] = useState<boolean>(false);
  const [appointmentSummary, setAppointmentSummary] = useState<any[]>([]);
  const [modalType, setModalType] = useState("");
  const [showUrl, setShowUrl] = useState(false);

  const [getAppointments, { loading }] = useLazyQuery(GETAPPOINTMENTS, {
    onCompleted: (data) => {
      let appointments = [...data.appointmentMany].sort((a: any, b: any) => {
        return new Date(b.date).valueOf() - new Date(a.date).valueOf();
      });

      if (appointments.length) {
        let uniqueTimeSlots = [...new Set(appointments.map((item) => item.date))];
        let timeSlotData = uniqueTimeSlots.map((timeSlot: Date) => {
          let grossAppointments = appointments.filter((appointment: IAppointment) => appointment.date === timeSlot);
          let netAppointments = appointments.filter((appointment: IAppointment) => appointment.date === timeSlot && !appointment.cancelled);
          let confirmedAppointments = appointments.filter(
            (appointment: IAppointment) => appointment.date === timeSlot && appointment.confirmed
          );
          let attendedAppointments = appointments.filter(
            (appointment: IAppointment) => appointment.date === timeSlot && appointment.cameIn
          );
          let grossGuests = grossAppointments.reduce(function (r: any, a: any) {
            return r + a.purchaserInfo.numberOfGuests;
          }, 0);
          let netGuests = netAppointments.reduce(function (r: any, a: any) {
            return r + a.purchaserInfo.numberOfGuests;
          }, 0);
          let confirmedGuests = confirmedAppointments.reduce(function (r: any, a: any) {
            return r + a.purchaserInfo.numberOfGuests;
          }, 0);
          let attendedGuests = attendedAppointments.reduce(function (r: any, a: any) {
            return r + a.purchaserInfo.numberOfGuests;
          }, 0);

          return {
            date: convertAllDates(timeZoneDate(timeSlot), "PPpp"),
            grossAppointments: grossAppointments.length,
            grossGuests: grossGuests,
            netAppointments: netAppointments.length,
            netGuests: netGuests,
            cancelledAppointments: grossAppointments.length - netAppointments.length,
            cancelledGuests: grossGuests - netGuests,
            confirmedAppointments: confirmedAppointments.length,
            confirmedGuests: confirmedGuests,
            attendedAppointments: attendedAppointments.length,
            attendedGuests: attendedGuests,
          };
        });
        let total = [
          ...timeSlotData,
          {
            date: "Total",
            grossAppointments: appointments.length,
            grossGuests: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.grossGuests;
            }, 0),
            netAppointments: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.netAppointments;
            }, 0),
            netGuests: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.netGuests;
            }, 0),
            cancelledAppointments:
              appointments.length -
              timeSlotData.reduce(function (r: any, a: any) {
                return r + a.netAppointments;
              }, 0),
            cancelledGuests:
              timeSlotData.reduce(function (r: any, a: any) {
                return r + a.grossGuests;
              }, 0) -
              timeSlotData.reduce(function (r: any, a: any) {
                return r + a.netGuests;
              }, 0),
            confirmedAppointments: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.confirmedAppointments;
            }, 0),
            confirmedGuests: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.confirmedGuests;
            }, 0),
            attendedAppointments: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.attendedAppointments;
            }, 0),
            attendedGuests: timeSlotData.reduce(function (r: any, a: any) {
              return r + a.attendedGuests;
            }, 0),
          },
        ];
        setAppointmentSummary(total!);
      }

      appointmentsDispatch({
        type: "UPDATEALL",
        payload: appointments,
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error getting appointments"));
    },
  });

  useQuery<IScheduleMany>(GETSCHEDULES, {
    variables: {
      filter: { projectIds: user?.projectAccess.map((projectAccess: IProjectAccess) => projectAccess.project._id) },
    },
    onCompleted: (data) => {
      let archivedSchedules = [...data.scheduleMany]
        .filter(
          (schedule: ISchedule) =>
            new Date(schedule?.schedules[schedule.schedules.length - 1].date!).valueOf()! < subDays(new Date(), 30).valueOf()
        )
        .sort(
          (a: any, b: any) =>
            new Date(b.schedules[b.schedules.length - 1].date).valueOf() - new Date(a.schedules[a.schedules.length - 1].date).valueOf()
        );
      let recentSchedules = [...data.scheduleMany]
        .filter(
          (schedule: ISchedule) =>
            new Date(schedule?.schedules[schedule.schedules.length - 1].date!).valueOf()! > subDays(new Date(), 30).valueOf()
        )
        .sort(
          (a: any, b: any) =>
            new Date(b.schedules[b.schedules.length - 1].date).valueOf() - new Date(a.schedules[a.schedules.length - 1].date).valueOf()
        );
      setSelectedRegistrants([]);
      setSchedules(recentSchedules);
      setArchivedSchedules(archivedSchedules);
    },
    onError: (error) => {
      console.log(error, "err");
    },
  });

  const [deleteAppointments] = useMutation(DELETEAPPOINTMENTS, {
    onCompleted: (data) => {
      storeDispatch(showSuccessSnackbar(data.deleteAppointments));
      setModalType("");
      storeDispatch(handleModal(false));
      appointmentsDispatch({
        type: "UPDATEALL",
        payload: appointmentsState.filter((appointment: IAppointment) => !selectedRegistrants.includes(appointment.registrant._id)),
      });
    },
    onError: (err) => {
      storeDispatch(showErrorSnackbar("Error creating appointment"));
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (schedule || archivedSchedule) {
      getAppointments({
        variables: { filter: { schedule: schedule ? schedule._id : archivedSchedule?._id, search: searchFilter, typeNot: "lease" } },
      });
    }
  }, [schedule, getAppointments, searchFilter, archivedSchedule]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Completed",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return <strong>{rowData.date}</strong>;
          } else return rowData.date;
        },
      },
      {
        Header: "Gross Appointments",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return (
              <strong>
                {rowData.grossAppointments} ({rowData.grossGuests + rowData.grossAppointments})
              </strong>
            );
          } else return `${rowData.grossAppointments} (${rowData.grossGuests + rowData.grossAppointments})`;
        },
      },
      {
        Header: "Net Appointments",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return (
              <strong>
                {rowData.netAppointments} ({rowData.netGuests + rowData.netAppointments})
              </strong>
            );
          } else return `${rowData.netAppointments} (${rowData.netGuests + rowData.netAppointments})`;
        },
      },
      {
        Header: "Cancelled Appointments",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return (
              <strong>
                {rowData.cancelledAppointments} ({rowData.cancelledGuests + rowData.cancelledAppointments})
              </strong>
            );
          } else return `${rowData.cancelledAppointments} (${rowData.cancelledGuests + rowData.cancelledAppointments})`;
        },
      },
      {
        Header: "Confirmed Appointments",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return (
              <strong>
                {rowData.confirmedAppointments} ({rowData.confirmedGuests + rowData.confirmedAppointments})
              </strong>
            );
          } else return `${rowData.confirmedAppointments} (${rowData.confirmedGuests + rowData.confirmedAppointments})`;
        },
      },
      {
        Header: "Attended Appointments",
        accessor: (rowData: any, index: number) => {
          if (rowData.date === "Total") {
            return (
              <strong>
                {rowData.attendedAppointments} ({rowData.attendedGuests + rowData.attendedAppointments})
              </strong>
            );
          } else return `${rowData.attendedAppointments} (${rowData.attendedGuests + rowData.attendedAppointments})`;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentSummary]);

  const handleSchedule = (id: string, type: string) => {
    setSchedule(null);
    setArchivedSchedule(null);
    if (type === "archived") {
      let selectedSchedule = archivedSchedules.find((schedule: ISchedule) => id === schedule._id);
      if (selectedSchedule) {
        setArchivedSchedule(selectedSchedule);
      }
    } else {
      let selectedSchedule = schedules.find((schedule: ISchedule) => id === schedule._id);
      if (selectedSchedule) {
        setSchedule(selectedSchedule);
      }
    }
  };

  const download = (type: string, data: any) => {
    let unitColumns = [
      {
        label: "Date",
        id: "date",
      },
      {
        label: "Gross Appointments",
        id: "grossAppointments",
      },
      {
        label: "Net Appointments",
        id: "netAppointments",
      },
      {
        label: "Cancelled Appointments",
        id: "cancelledAppointments",
      },
      {
        label: "Confirmed Appointments",
        id: "confirmedAppointments",
      },
      {
        label: "Attended Appointments",
        id: "attendedAppointments",
      },
    ];

    let widths: any = {
      date: 15,
      grossAppointments: 15,
      netAppointments: 15,
      cancelledAppointments: 15,
      confirmedAppointments: 15,
      attendedAppointments: 15,
    };

    let pdfWidths: any = {
      date: 200,
      grossAppointments: 200,
      netAppointments: 200,
      cancelledAppointments: 200,
      confirmedAppointments: 200,
      attendedAppointments: 200,
    };

    let sheetTitle = "Appointments";

    if (schedule) {
      sheetTitle = `Appointments Summary`;
    }

    let allData = appointmentSummary.map((data: any) => {
      return {
        date: `${data.date}`,
        grossAppointments: `${data.grossAppointments} (${data.grossGuests + data.grossAppointments})`,
        netAppointments: `${data.netAppointments} (${data.netGuests + data.netAppointments})`,
        cancelledAppointments: `${data.cancelledAppointments} (${data.cancelledGuests + data.cancelledAppointments})`,
        confirmedAppointments: `${data.confirmedAppointments} (${data.confirmedGuests + data.confirmedAppointments})`,
        attendedAppointments: `${data.attendedAppointments} (${data.attendedGuests + data.attendedAppointments})`,
      };
    });

    if (type === "excel") {
      downloadExcel([allData], [unitColumns], [], [[widths]], [sheetTitle], sheetTitle);
    } else {
      downloadPdf([allData], [unitColumns], [], [pdfWidths], [sheetTitle], sheetTitle);
    }
  };

  const handleShowModal = (type: string) => {
    setModalType(type);
    storeDispatch(handleModal(true));
  };

  const modalContent = () => {
    switch (modalType) {
      case "mail":
        return <MassMail id={"registrant"} selectedRegistrants={selectedRegistrants} project={null} schedule={schedule} />;
      case "text":
        return <MassText id={"registrant"} selectedRegistrants={selectedRegistrants} project={schedule ? schedule.project._id : null} />;
      case "delete":
        return (
          <Box>
            <Typography variant="h2">Are you sure you would like to delete these appointments?</Typography>
            <FlexBetween sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  setModalType("");
                  storeDispatch(handleModal(false));
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => deleteAppointments({ variables: { registrantIds: selectedRegistrants, scheduleId: schedule?._id } })}
              >
                Confirm
              </Button>
            </FlexBetween>
          </Box>
        );
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <BookAnAppointmentDialog
        open={bookAppointmentOpen}
        setOpen={setBookAppointmentOpen}
        appointmentsDispatch={appointmentsDispatch}
        selectedSchedule={schedule}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Recent Schedule/Events</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-schedule-select"
                name={"schedule"}
                label={"Recent Schedule/Events"}
                value={schedule ? schedule?._id : ""}
                onChange={(e) => handleSchedule(e.target.value, "schedule")}
              >
                {schedules.map((schedule: ISchedule, index: number) => {
                  return (
                    <MenuItem key={index} value={schedule._id}>
                      {schedule.project.name}
                      {" - "}
                      <strong> {schedule.name} </strong> - (
                      {convertAllDates(schedule.schedules[0].date, "P") ===
                      convertAllDates(schedule.schedules[schedule.schedules.length - 1].date, "P")
                        ? convertAllDates(schedule.schedules[0].date, "P")
                        : `${convertAllDates(schedule.schedules[0].date, "P")} - ${convertAllDates(
                            schedule.schedules[schedule.schedules.length - 1].date,
                            "P"
                          )}`}
                      )
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-label">Archived Schedule/Events</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-archive-select"
                name={"archivedSchedule"}
                label={"Archived Schedule/Events"}
                value={archivedSchedule ? archivedSchedule?._id : ""}
                onChange={(e) => handleSchedule(e.target.value, "archived")}
              >
                {archivedSchedules.map((schedule: ISchedule, index: number) => {
                  return (
                    <MenuItem key={index} value={schedule._id}>
                      {schedule.project.name} - {schedule.name} - ({convertAllDates(schedule.schedules[0].date, "P")} -{" "}
                      {convertAllDates(schedule.schedules[schedule.schedules.length - 1].date, "P")})
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {appointmentsState.length && user?.type !== "Developer" ? <GlobalModal>{modalContent()}</GlobalModal> : null}
      {appointmentsState.length ? (
        <Box sx={{ mt: 2 }}>
          <StandardTable user={user} download={download} loading={loading} data={appointmentSummary} columns={columns} />
        </Box>
      ) : null}
      <Box sx={{ alignSelf: "center", mt: 2, display: "flex", flexWrap: "wrap" }}>
        {appointmentsState.length && selectedRegistrants.length && user?.type !== "Developer" ? (
          <Button sx={{ mr: 1, mt: 1 }} onClick={() => handleShowModal("mail")} color="primary" variant="contained">
            Mass Mail
          </Button>
        ) : null}
        {appointmentsState.length && selectedRegistrants.length && user?.type !== "Developer" ? (
          <Button sx={{ mr: 1, mt: 1 }} onClick={() => handleShowModal("text")} color="primary" variant="contained">
            Mass Text
          </Button>
        ) : null}
        <Button
          sx={{ mr: 1, mt: 1 }}
          variant="contained"
          onClick={() => {
            setBookAppointmentOpen(true);
          }}
        >
          Book Appointment
        </Button>
        {schedule ? (
          !showUrl ? (
            <Button sx={{ mr: 1, mt: 1 }} color="primary" variant="contained" onClick={() => setShowUrl(true)}>
              Show Url
            </Button>
          ) : (
            <Box sx={{ mr: 1, mt: 1 }}>
              <strong>
                {schedule && schedule.shortUrl
                  ? process.env.REACT_APP_ENV === "local"
                    ? `localhost:3001/book/${schedule.shortUrl}`
                    : `https://portal.rdsre.ca/book/${schedule.shortUrl}`
                  : process.env.REACT_APP_ENV === "local"
                  ? `localhost:3001/${urlName(schedule.project.developerName)}/${urlName(
                      schedule.project.name
                    )}/public/book-an-appointment/${schedule._id}`
                  : `https://portal.rdsre.ca/${urlName(schedule.project.developerName)}/${urlName(
                      schedule.project.name
                    )}/public/book-an-appointment/${schedule._id}`}
              </strong>
            </Box>
          )
        ) : null}
        {appointmentsState.length && selectedRegistrants.length && user?.type !== "Developer" ? (
          <Button sx={{ mr: 1, mt: 1 }} onClick={() => handleShowModal("delete")} color="error" variant="contained">
            Delete Appointments
          </Button>
        ) : null}
      </Box>
      <AppointmentsTable
        appointments={appointmentsState}
        loading={loading}
        appointmentsDispatch={appointmentsDispatch}
        schedule={schedule ? schedule : archivedSchedule}
        setSchedule={schedule ? setSchedule : setArchivedSchedule}
        schedules={schedule ? schedules : archivedSchedules}
        selectedAppointment={selectedAppointment}
        setSelectedAppointment={setSelectedAppointment}
        dialogOpen={dialogOpen}
        dialogType={dialogType}
        setDialogOpen={setDialogOpen}
        setDialogType={setDialogType}
        setSearchFilter={setSearchFilter}
        selectedRegistrants={selectedRegistrants}
        setSelectedRegistrants={setSelectedRegistrants}
      />
    </Box>
  );
};

const GETSCHEDULES = gql`
  query scheduleMany($filter: FilterFindManyScheduleInput) {
    scheduleMany(filter: $filter, limit: 10000) {
      _id
      active
      allowGuests
      public
      name
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      locations
      type
      access
      shortUrl
      project {
        _id
        developerName
        name
      }
    }
  }
`;

/* Queries */
const GETAPPOINTMENTS = gql`
  query appointmentMany($filter: FilterFindManyAppointmentInput) {
    appointmentMany(filter: $filter, limit: 100000) {
      _id
      project {
        _id
        salesOffice
        name
      }
      user {
        fullName
        email
        realtor {
          _id
          fullName
          directPhone
        }
      }
      date
      location
      purchaserInfo {
        firstName
        lastName
        email
        primaryPhone
        numberOfGuests
      }
      questions {
        questionId {
          _id
          name
          question
          type
          choices {
            choice
            followUp {
              _id
            }
          }
        }
        answer
      }
      status
      type
      notes
      confirmed
      cancelled
      cameIn
      noResponse
      salesNotes
      salesRep {
        _id
        fullName
      }
      registrant {
        _id
        email
        rating
        realtorType
        project {
          _id
        }
        statuses {
          name
          questions {
            questionId {
              _id
              question
            }
            answer
          }
          createdAt
        }
        tags
      }
      createdAt
    }
  }
`;

const DELETEAPPOINTMENTS = gql`
  mutation deleteAppointments($registrantIds: [MongoID!], $scheduleId: MongoID) {
    deleteAppointments(registrantIds: $registrantIds, scheduleId: $scheduleId)
  }
`;

export default Events;
