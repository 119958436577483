import { useQuery, gql } from "@apollo/client";

import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar } from "../snackbar/snackbarSlice";
import { setLists, setProjectSetting } from "./projectSettingSlice";
import { IUser } from "../../types/user";

export const useProjectSettingQuery = (id: string, project: string | null, setting: string) => {
  const dispatch = useAppDispatch();

  return useQuery(GETPROJECTSETTINGS, {
    skip: project! === id && setting !== "",
    fetchPolicy: "network-only",
    variables: { filter: { project: id } },
    onCompleted: (data) => {
      dispatch(setProjectSetting(data.crmProjectSettingOne));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

export const useListsQuery = (project: string, user: IUser) => {
  const dispatch = useAppDispatch();

  let query = { project: project, archived: false, OR: [{ view: "Sales" }, { view: "All" }, { view: "Admin" }] };

  if (user?.type === "Sales") {
    query = { project: project, archived: false, OR: [{ view: "Sales" }, { view: "All" }] };
  }
  if (user?.type === "Admin") {
    query = { project: project, archived: false, OR: [{ view: "Admin" }, { view: "All" }] };
  }

  return useQuery(GETLISTS, {
    variables: { filter: query },
    onCompleted: (data) => {
      dispatch(setLists(data.listMany));
    },
    onError: (err) => {
      dispatch(showErrorSnackbar(err.message));
    },
  });
};

const GETPROJECTSETTINGS = gql`
  query crmProjectSettingOne($filter: FilterFindOneCrmProjectSettingInput) {
    crmProjectSettingOne(filter: $filter) {
      _id
      questions {
        name
        type
        subQuestion
        registrantType
        question
        display
        choices {
          choice
          followUp {
            _id
            name
            question
            type
            subQuestion
            display
          }
          _id
        }
        _id
      }
      processTemplates {
        _id
        type
        name
        default
        userType
        sourceType
        viewType
        stepTemplates {
          _id
          name
        }
        timeBetweenSteps
        createdAt
      }
      shortTermUsers {
        _id
        fullName
      }
      shortTermRealtorWalkIn {
        _id
        fullName
      }
      shortTermHasRealtorWalkIn {
        _id
        fullName
      }
      shortTermNoRealtorWalkIn {
        _id
        fullName
      }
      sales {
        _id
        firstName
        lastName
        fullName
        type
        email
      }
      online {
        _id
        firstName
        lastName
        fullName
        type
        email
      }
      scrum {
        _id
        firstName
        lastName
        fullName
        type
        email
      }
      admins {
        _id
        firstName
        lastName
        fullName
        type
        email
      }
      tags
    }
  }
`;

const GETLISTS = gql`
  query listMany($filter: FilterFindManyListInput) {
    listMany(filter: $filter) {
      _id
      name
    }
  }
`;
