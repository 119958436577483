import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import { addHours } from "date-fns";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Backdrop,
  CircularProgress,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
} from "@mui/material";

import { selectProject } from "../../../features/project/projectSlice";
import { selectRegistrant } from "../../../features/registrants/registrantsSlice";
import { selectUser } from "../../../features/auth/authSlice";
import { useAppDispatch } from "../../../app/hooks";
import { getSalesRepObj } from "../../../features/user/userHooks";
import { showSuccessSnackbar, showErrorSnackbar } from "../../../features/snackbar/snackbarSlice";
import { IUser } from "../../../types/user";
import AppointmentCalendar from "./AppointmentCalendar";
import { selectUsers } from "../../../features/projectSetting/projectSettingSlice";
import { ISchedule, IScheduleMany } from "../../../types/schedule";
import { formatPhoneNumber, timeZoneDate, addTimeZoneDate } from "../../../utils/function";
import { IProject } from "../../../types/project";
import { IProjectAccess } from "../../../types/user";
import { IQuestion } from "../../../types/question";
import { setProcess, setRegistrant, setActiveProcesses } from "../../../features/registrants/registrantsSlice";

const BookAnAppointmentDialog = (props: AppointmentDialogProps) => {
  const { open, setOpen, appointmentsDispatch, selectedSchedule } = props;
  /* Redux */
  const storeDispatch = useAppDispatch();
  const selectedRegistrant = useSelector(selectRegistrant);
  const project = useSelector(selectProject);
  const user = useSelector(selectUser);
  const users = useSelector(selectUsers);

  /* States */
  const [firstName, setFirstName] = useState<string | undefined>(selectedRegistrant?.firstName);
  const [lastName, setLastName] = useState<string | undefined>(selectedRegistrant?.lastName);
  const [email, setEmail] = useState<string | undefined>(selectedRegistrant?.email);
  const [primaryPhoneNumber, setPrimaryPhoneNumber] = useState<string | undefined>(selectedRegistrant?.primaryPhone);
  const [numberOfGuests, setNumberOfGuests] = useState<string | undefined>("0");
  const [location, setLocation] = useState<string>("");
  const [date, setDate] = useState<Date | null | undefined>(new Date());
  const [formUser, setFormUser] = useState<string>("");
  const [virtual, setVirtual] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [salesRep, setSalesRep] = useState<IUser | undefined>();
  const [schedules, setSchedules] = useState<ISchedule[]>([]);
  const [schedule, setSchedule] = useState<ISchedule | null>(null);
  const [selectedProject, setSelectedProject] = useState<IProject | null>(project ? project : null);
  const [noEmail, setNoEmail] = useState<boolean>(false);
  /* Misc functions */
  /* Queries/Mutations */

  const [getSchedules] = useLazyQuery<IScheduleMany>(GETSCHEDULES, {
    fetchPolicy: "cache-first",
    variables: {
      filter: { project: selectedProject?._id, active: true },
    },
    onCompleted: (data) => {
      let allSchedules = [...data.scheduleMany].sort(
        (a: any, b: any) =>
          new Date(b.schedules[b.schedules.length - 1].date).valueOf() - new Date(a.schedules[a.schedules.length - 1].date).valueOf()
      );
      setSchedules(allSchedules);
    },
    onError: (error) => {
      console.log(error, "err");
    },
  });

  const [editCalendar] = useMutation(EDITCALENDAR, {
    onCompleted: (data) => {},
    onError: (err) => {
      console.log(err, "err");
    },
  });

  useEffect(() => {
    if (open) {
      getSchedules();
    }
    if (selectedSchedule) {
      setSchedule(selectedSchedule);
      setSelectedProject(selectedSchedule.project);
    }
  }, [selectedProject, getSchedules, selectedSchedule, open]);

  const [createAppointmentMutation] = useMutation(CREATEAPPOINTMENT, {
    onCompleted: (data) => {
      setDisabled(false);
      setOpen(false);
      appointmentsDispatch({
        type: "ADD",
        payload: {
          value: data.appointmentCreateOne.appointment,
        },
      });

      if (data.appointmentCreateOne.appointment.salesRep) {
        let date = new Date(timeZoneDate(data.appointmentCreateOne.appointment.date));
        let endDate = addHours(new Date(timeZoneDate(data.appointmentCreateOne.appointment.date)), 1);
        editCalendar({
          variables: {
            calendarId: user?.email,
            eventId: null,
            summary: `${
              data.appointmentCreateOne.appointment.project ? data.appointmentCreateOne.appointment.project.name : ""
            } Appointment`,
            start: date,
            end: endDate,
            location: data.appointmentCreateOne.appointment.location,
            attendees: [
              {
                email: data.appointmentCreateOne.appointment.purchaserInfo.email,
                organizer: null,
                responseStatus: "needsAction",
                self: false,
              },
              {
                email: data.appointmentCreateOne.appointment.salesRep.email,
                organizer: null,
                responseStatus: "needsAction",
                self: data.appointmentCreateOne.appointment.salesRep.email === user?.email ? true : false,
              },
            ],
            googleMeetsLink: false,
            type: "edit",
          },
        });
      }
      setFirstName("");
      setLastName("");
      setEmail("");
      setPrimaryPhoneNumber("");
      setLocation("");
      setSelectedProject(null);
      setSchedule(null);
      setNoEmail(false);
      if (selectedRegistrant && selectedRegistrant.process) {
        storeDispatch(
          setRegistrant({
            ...selectedRegistrant,
            process: [...selectedRegistrant?.process!, ...data.appointmentCreateOne.process],
          })
        );
        storeDispatch(setActiveProcesses([...data.appointmentCreateOne.process]));
      }
      storeDispatch(setProcess(data.appointmentCreateOne.process));
      storeDispatch(showSuccessSnackbar("Appointment successfully created!"));
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const createAppointment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disabled) return;
    setDisabled(true);

    const projectId = project ? project._id : selectedProject?._id;
    const userId = user?._id;
    const submitDate = addTimeZoneDate(new Date(date!)).toISOString();
    const submitLocation = virtual ? "Virtual" : location;
    const submitNumberOfGuests = numberOfGuests ? parseInt(numberOfGuests) : null;
    const purchaserInfo = {
      firstName,
      lastName,
      email,
      primaryPhone: primaryPhoneNumber,
      numberOfGuests: submitNumberOfGuests,
      _id: selectedRegistrant?._id,
    };

    const questions: IQuestion[] = [];
    const status = "Pending";
    const type = "lead";
    const confirmed = false;
    const cancelled = false;
    const salesRepId = getSalesRepObj(users, formUser)?._id;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !primaryPhoneNumber ||
      submitNumberOfGuests === null ||
      !submitLocation ||
      !submitDate ||
      !projectId
    ) {
      setDisabled(false);
      return storeDispatch(showErrorSnackbar("Missing fields."));
    }

    createAppointmentMutation({
      variables: {
        projectId,
        userId,
        schedule: schedule ? schedule._id : null,
        submitDate,
        submitLocation,
        purchaserInfo,
        questions,
        status,
        type,
        confirmed,
        cancelled,
        salesRepId: salesRepId ? salesRepId : null,
        virtual,
        noEmail,
      },
    });
  };

  const handleProject = (e: any) => {
    let selectedProject = user?.projectAccess.find((projectAccess: IProjectAccess) => projectAccess.project._id === e);
    if (selectedProject) {
      setSchedule(null);
      setSelectedProject(selectedProject.project);
    }
  };

  return (
    <Backdrop sx={{ backgroundColor: "rgb(100, 100, 100, 0)", zIndex: 1 }} open={open} onClick={() => (!disabled ? setOpen(false) : null)}>
      <Dialog sx={{ xIndex: 2 }} open={open}>
        <DialogTitle onClick={(e) => e.stopPropagation()}>
          <Typography>
            <strong>Book an Appointment</strong>
          </Typography>
        </DialogTitle>
        <DialogContent onClick={(e) => e.stopPropagation()} dividers={true}>
          <DialogActions>
            <form onSubmit={createAppointment}>
              <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", mb: 1 }}>
                <Typography>
                  <strong>Basic Info</strong>
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={virtual}
                      disabled={disabled}
                      onChange={(e) => {
                        setVirtual(e.target.checked);
                      }}
                    />
                  }
                  label="Virtual"
                />
              </Box>
              <Grid container spacing={2}>
                {!project ? (
                  <Grid item xs={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-project">Project</InputLabel>
                      <Select
                        label="Project"
                        sx={{ width: "100%" }}
                        labelId="demo-simple-select-project"
                        id="demo-simple-project"
                        value={selectedProject ? selectedProject._id : ""}
                        onChange={(e: any) => {
                          handleProject(e.target.value);
                        }}
                        required
                      >
                        {user?.projectAccess.map((projectAccess: IProjectAccess, index: number) => {
                          return (
                            <MenuItem key={index} value={projectAccess.project._id}>
                              {projectAccess.project.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required={!firstName}
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required={!lastName}
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required={!email}
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required={!primaryPhoneNumber}
                    label="Primary Phone Number"
                    value={primaryPhoneNumber}
                    onChange={(e) => setPrimaryPhoneNumber(formatPhoneNumber(e.target.value))}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    required={!numberOfGuests}
                    type="number"
                    label="Number of Additional Guests"
                    value={numberOfGuests}
                    inputProps={{ min: 0, max: 999 }}
                    onChange={(e) => setNumberOfGuests(e.target.value)}
                    disabled={disabled}
                  />
                </Grid>
                {virtual && <Box sx={{ width: "100%" }} />}
                {!virtual && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      required={!location}
                      label="Location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      disabled={disabled}
                    />
                  </Grid>
                )}
              </Grid>
              <Typography sx={{ mt: 2 }}>
                <strong>Specialist and Time</strong>
              </Typography>
              <Grid container spacing={2}>
                {selectedProject ? (
                  <Grid item xs={12}>
                    <Autocomplete
                      sx={{ mb: 1 }}
                      options={users.map((user: IUser) => `${user.firstName} ${user.lastName} (${user.type})`)}
                      onChange={(e) => {
                        setFormUser((e.target as HTMLInputElement).innerText);
                        setSalesRep(getSalesRepObj(users, (e.target as HTMLInputElement).innerText));
                      }}
                      renderInput={(params) => <TextField {...params} label="Specialist" />}
                      value={formUser}
                      fullWidth
                      disabled={disabled}
                    />
                  </Grid>
                ) : null}
                {open && (
                  <>
                    <Grid item xs={12}>
                      <AppointmentCalendar
                        user={salesRep}
                        setDate={setDate}
                        setFormError={setDisabled}
                        schedules={schedules}
                        schedule={schedule ? schedule : selectedSchedule!}
                        setSchedule={setSchedule}
                        project={selectedProject}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    control={<Checkbox checked={noEmail} onChange={() => setNoEmail(!noEmail)} />}
                    label={"Do not send Confirmation Email"}
                  />
                </Grid>
                <Box sx={{ display: "flex", px: 2, mt: 2 }}>
                  <Button sx={{ mr: 2 }} color="warning" variant="contained" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button id="create" sx={{ mr: 2 }} color="success" variant="contained" type="submit" disabled={disabled}>
                    Submit {disabled ? <CircularProgress size={16} sx={{ ml: 1 }} /> : null}
                  </Button>
                </Box>
              </Grid>
            </form>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Backdrop>
  );
};

type AppointmentDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  appointmentsDispatch: any;
  selectedSchedule?: ISchedule | null;
};

const CREATEAPPOINTMENT = gql`
  mutation createAppointment(
    $projectId: MongoID!
    $userId: MongoID
    $schedule: MongoID
    $submitDate: Date!
    $submitLocation: String!
    $purchaserInfo: AppointmentPurchaserInfoInput!
    $questions: [AppointmentQuestionsInput]
    $status: EnumAppointmentStatus!
    $type: EnumAppointmentType
    $confirmed: Boolean
    $cancelled: Boolean
    $salesRepId: MongoID
    $virtual: Boolean
    $noEmail: Boolean
  ) {
    appointmentCreateOne(
      record: {
        project: $projectId
        user: $userId
        schedule: $schedule
        date: $submitDate
        location: $submitLocation
        purchaserInfo: $purchaserInfo
        questions: $questions
        status: $status
        type: $type
        confirmed: $confirmed
        cancelled: $cancelled
        salesRep: $salesRepId
        virtual: $virtual
      }
      noEmail: $noEmail
    ) {
      appointment {
        _id
        project {
          _id
          salesOffice
          name
        }
        schedule {
          _id
        }
        user {
          realtor {
            _id
            fullName
            directPhone
          }
        }
        date
        location
        purchaserInfo {
          firstName
          lastName
          email
          primaryPhone
          numberOfGuests
        }
        questions {
          questionId {
            name
            question
            type
            choices {
              choice
              followUp {
                _id
              }
            }
          }
          answer
        }
        status
        type
        notes
        confirmed
        cancelled
        cameIn
        salesNotes
        salesRep {
          _id
          fullName
          email
        }
        registrant {
          _id
          email
          rating
          realtorType
          project {
            _id
          }
          statuses {
            name
            questions {
              questionId {
                _id
                question
              }
              answer
            }
            createdAt
          }
          tags
        }
        createdAt
      }
      process {
        _id
        currentProcess
        default
        viewType
        steps {
          _id
          completed
          default
          dueDate
          type
          emailTemplate {
            name
            _id
          }
          user {
            _id
            fullName
          }
          name
        }
        timeBetweenSteps
        processType
        name
        users {
          _id
          fullName
        }
      }
    }
  }
`;

const GETSCHEDULES = gql`
  query scheduleMany($filter: FilterFindManyScheduleInput) {
    scheduleMany(filter: $filter, limit: 10000) {
      _id
      active
      allowGuests
      public
      name
      schedules {
        date
        length
        timeBetweenAppointments
        timeStart
        timeEnd
        numberOfAppointments
      }
      locations
      type
      access
      project {
        _id
        name
      }
    }
  }
`;

const EDITCALENDAR = gql`
  mutation editCalendar(
    $calendarId: String
    $eventId: String
    $summary: String!
    $start: Date!
    $end: Date!
    $location: String
    $attendees: [NewAttendeesInput]
    $googleMeetsLink: Boolean!
    $type: String!
  ) {
    editCalendar(
      calendarId: $calendarId
      eventId: $eventId
      summary: $summary
      start: $start
      end: $end
      location: $location
      attendees: $attendees
      googleMeetsLink: $googleMeetsLink
      type: $type
    ) {
      id
    }
  }
`;

export default BookAnAppointmentDialog;
