/* Dependency Imports */
import { useState, MouseEvent } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLazyQuery, gql, useSubscription } from "@apollo/client";
import {
  AppBar,
  Toolbar,
  Box,
  Autocomplete,
  TextField,
  InputAdornment,
  Button,
  Menu,
  MenuItem,
  Paper,
  IconButton,
  Collapse,
  FormControl,
} from "@mui/material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import TextsmsIcon from "@mui/icons-material/Textsms";

/* Project Imports */
import { theme } from "../../theme";
import { useLogout, useSelectUser } from "../../features/auth/authHooks";
import { IProjectAccess } from "../../types/user";
import { selectProject } from "../../features/project/projectSlice";
import { useAppDispatch } from "../../app/hooks";
import { addNotification } from "../../features/notifications/notificationSlice";
import { IProject } from "../../types/project";
import RegistrantNotification from "../registrantNotification/RegistrantNotification";
import { convertAllDates } from "../../utils/function";

const Header = () => {
  /* Redux */
  const auth = useSelectUser();
  const project = useSelector(selectProject);
  const { projectid } = useParams();
  const location = useLocation();

  /* States */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [projectEl, setProjectEl] = useState<null | HTMLElement>(null);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const projectOpen = Boolean(projectEl);
  const [searchValues, setSearchValues] = useState<ISearchValues[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [key, setKey] = useState<number>(Math.floor(Math.random() * 1000000 + 1));

  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  /* Queries/Mutations */
  const logout = useLogout();

  const [searchRegistrant, { loading: searchLoading }] = useLazyQuery(GETREGISTRANTS, {
    onCompleted: (data) => {
      setSearchValues(data.searchRegistrants);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  /* Functions */

  const handleSearch = (e: any, value: string) => {
    if (value) {
      searchRegistrant({ variables: { search: value } });
    }
  };

  const CustomPaper = (props: any) => {
    return (
      <Paper
        sx={{
          "& .MuiAutocomplete-listbox": {
            maxHeight: "none",
            padding: 0,
            cursor: "pointer",
          },
        }}
        elevation={8}
        {...props}
      />
    );
  };

  const handleNavigate = (id: string, projectId: string) => {
    setSearchOpen(false);
    navigate(`/dashboard/project/${projectId}/registrant/${id}`);
  };

  const handleProjectNavigate = (id: string) => {
    setProjectEl(null);
    navigate(`/dashboard/project/${id}`);
  };

  useSubscription(CREATEDREGISTRANT, {
    variables: {
      projectId: project?._id || "",
    },
    onSubscriptionData: (data) => {
      const newRegistrant = data.subscriptionData.data.createdRegistrant.registrant;
      let text = `Thank you ${newRegistrant.firstName} ${newRegistrant.lastName} for registering!`;
      if (data.subscriptionData.data.createdRegistrant.appointment) {
        text = `Thank you ${newRegistrant.firstName} ${newRegistrant.lastName} for coming in. Appointment at ${convertAllDates(
          data.subscriptionData.data.createdRegistrant.appointment.date,
          "PPpp"
        )}`;
      }
      dispatch(
        addNotification({
          message: text,
          duration: 8000,
          colour: newRegistrant.project?.portal?.primaryColor || "black",
          type: "registrant",
          link: `/dashboard/project/${newRegistrant.project._id}/registrant/${newRegistrant._id}`,
        })
      );
    },
  });

  const handleAdvanceSearch = (e: any, value: any) => {
    e.preventDefault();
    if (value.length > 2) {
      setKey(Math.floor(Math.random() * 1000000 + 1));
      navigate(`/dashboard/search-page?search=${value}`);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: project?.portal && projectid ? project?.portal.primaryColor : "primary.main",
          overflow: "auto",
          py: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link style={{ textDecoration: "none" }} to={`/dashboard`}>
            <Box sx={{ mr: { xs: 1, sm: 1.5 } }}>
              <img src={require("../../assets/rds-logo-transparent.png")} alt="logo" width="50px" />
              <Box
                sx={{
                  fontSize: "8px",
                  mt: -1,
                  color: "#fff",
                  textDecoration: "none",
                  textAlign: "center",
                  display: "none",
                  [theme.breakpoints.up("sm")]: {
                    display: "block",
                  },
                }}
              >
                Home
              </Box>
            </Box>
          </Link>
          {project && location.pathname !== `/dashboard/project/${project?._id}` && auth?.type !== "Marketing" ? (
            <Link style={{ color: "#fff", textDecoration: "none" }} to={`/dashboard/project/${project?._id}`}>
              <Box sx={{ mr: { xs: 2, sm: 2.5 } }}>
                <DashboardIcon sx={{ fontSize: "40px", pt: 1 }} />
                <Box
                  sx={{
                    fontSize: "8px",
                    color: "#fff",
                    textDecoration: "none",
                    textAlign: "center",
                    display: "none",
                    [theme.breakpoints.up("sm")]: {
                      display: "block",
                    },
                  }}
                >
                  Project
                </Box>
              </Box>
            </Link>
          ) : null}
          {!showSearch && auth?.type !== "Marketing" ? (
            <Box
              sx={{
                "@media (max-width: 600px)": {
                  display: "none",
                },
                display: "block",
              }}
            >
              <Autocomplete
                key={key}
                options={searchValues}
                sx={{ minWidth: { xs: "200px", sm: "300px" }, width: "auto", mr: { xs: 0.5, sm: 1.5 }, mt: 1.5, mb: 1.5 }}
                open={searchOpen}
                onOpen={() => {
                  setSearchOpen(true);
                }}
                onClose={() => {
                  setSearchOpen(false);
                }}
                filterOptions={(x) => x}
                PaperComponent={CustomPaper}
                ListboxProps={{ style: { maxHeight: "calc(100vh - 100px)" } }}
                freeSolo
                onInputChange={(e, value) => handleSearch(e, value)}
                onChange={handleAdvanceSearch}
                loading={searchLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        paddingRight: "9px !important",
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                renderOption={(props: any, option: any) => {
                  return (
                    <Box key={option.registrantIds[0]._id} sx={{ borderBottom: "1px solid #000" }}>
                      <Box sx={{ p: 1.5 }}>
                        <Box onClick={() => handleNavigate(option.registrantIds[0]._id, option.projects[option.projects.length - 1]._id)}>
                          {option.fullName} {option.email ? `(${option.email})` : null}
                        </Box>
                        {option.primaryPhone ? <Box sx={{ fontSize: "10px" }}>{option.primaryPhone}</Box> : null}
                      </Box>
                      <Box sx={{ display: "flex", px: 1 }}>
                        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                          {option.registrantIds.map((registrant: any, index: number) => {
                            return (
                              <Box
                                key={index}
                                sx={{
                                  fontSize: "10px",
                                  p: 0.5,
                                  backgroundColor: theme.palette.secondary.main,
                                  color: "#fff",
                                  mr: 1,
                                  mb: 1,
                                  borderRadius: 2,
                                }}
                                onClick={() => handleNavigate(registrant._id, registrant.project._id)}
                              >
                                {registrant.project.name}
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
          ) : null}
        </Box>
        <Box sx={{ display: "flex", flexWrap: { md: "nowrap" } }}>
          <Box sx={{ width: { xs: "100%" }, display: "flex", justifyContent: { xs: "space-evenly", md: "flex-end" } }}>
            <IconButton
              onClick={() => {
                if (showSearch) {
                  setShowSearch(!showSearch);
                }
                setShowMenu(!showMenu);
              }}
              sx={{
                "@media (max-width: 600px)": {
                  display: "inline-flex",
                  alignSelf: "center",
                },
                display: "none",
              }}
            >
              <MenuIcon sx={{ alignSelf: "center", color: "#fff" }} />
            </IconButton>
            <IconButton
              onClick={() => {
                if (showMenu) {
                  setShowMenu(!showMenu);
                }
                setShowSearch(!showSearch);
              }}
              sx={{
                "@media (max-width: 600px)": {
                  display: "inline-flex",
                  alignSelf: "center",
                },
                display: "none",
              }}
            >
              <SearchIcon sx={{ alignSelf: "center", color: "#fff" }} />
            </IconButton>
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? <RegistrantNotification /> : null}
            {/* {auth?.type !== "Developer" && auth?.type !== "Marketing" ? <NotificationBell /> : null} */}
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? (
              <IconButton
                sx={{
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                  display: "inline-flex",
                  alignSelf: "center",
                }}
                onClick={() => navigate(`/dashboard/notifications`)}
              >
                <PermPhoneMsgIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? (
              <IconButton
                onClick={() => {
                  navigate(`/dashboard/sms`);
                }}
              >
                <TextsmsIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
            {auth?.type !== "Marketing" ? (
              <IconButton
                sx={{
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                  display: "inline-flex",
                  alignSelf: "center",
                }}
                onClick={() => navigate(`/dashboard/scheduled-mailings`)}
                aria-label="close"
              >
                <ForwardToInboxIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
            {auth?.type !== "Marketing" ? (
              <IconButton
                sx={{
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                  display: "inline-flex",
                  alignSelf: "center",
                }}
                onClick={() => navigate(`/dashboard/appointments`)}
              >
                <CalendarMonthIcon sx={{ color: `#fff` }} />
              </IconButton>
            ) : null}
            {project && projectid && auth?.type !== "Marketing" ? (
              <IconButton
                sx={{
                  "@media (max-width: 600px)": {
                    display: "none",
                  },
                  display: "inline-flex",
                  alignSelf: "center",
                }}
                onClick={() => {
                  navigate(`/dashboard/project/${projectid}/list/came-ins-daily?row=15&page=1`);
                }}
              >
                <DirectionsWalkIcon sx={{ color: `#fff` }} />
              </IconButton>
            ) : null}
          </Box>
          <Box sx={{ width: { xs: "100%" }, display: "flex" }}>
            {project && projectid && auth?.type !== "Marketing" ? (
              <Button
                id="basic-button"
                aria-controls={projectOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={projectOpen ? "true" : undefined}
                onClick={(e: MouseEvent<HTMLButtonElement>) => setProjectEl(e.currentTarget)}
                sx={{ fontSize: { xs: 10, sm: 13 }, color: "#fff" }}
              >
                {project.name}
              </Button>
            ) : null}
            {auth ? (
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
                sx={{ width: auth?.type !== "Marketing" ? "auto" : "100%", fontSize: { xs: 10, sm: 13 }, color: "#fff" }}
              >
                {auth.fullName}
              </Button>
            ) : null}
          </Box>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={projectEl}
          open={projectOpen}
          onClose={() => setProjectEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {[...auth?.projectAccess!]
            .sort((a: any, b: any) => a.project.name.localeCompare(b.project.name))
            .map((project: IProjectAccess, index: number) => {
              return (
                <MenuItem sx={{ width: "100%" }} key={index} onClick={() => handleProjectNavigate(project.project?._id!)}>
                  {project.project.name}
                </MenuItem>
              );
            })}
        </Menu>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={() => setAnchorEl(null)}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => navigate(`/user`)}>Profile</MenuItem>
          <MenuItem onClick={() => logout()}>Logout</MenuItem>
          {project && auth?.type !== "Developer" ? (
            <div>
              <MenuItem
                rel="noopener noreferrer"
                component="a"
                href={
                  process.env.REACT_APP_ENV === "local"
                    ? `http://localhost:3000/${project._id}/dashboard/price-grid`
                    : `https://app.rdsre.ca/${project._id}/dashboard/price-grid`
                }
                target="_blank"
              >
                Price Grid
              </MenuItem>
              <MenuItem
                rel="noopener noreferrer"
                component="a"
                href={process.env.REACT_APP_ENV === "local" ? `http://localhost:3001` : `https://portal.rdsre.ca`}
                target="_blank"
              >
                Broker Portal
              </MenuItem>
            </div>
          ) : null}
        </Menu>
      </Toolbar>
      {showSearch ? (
        <Collapse
          in={showSearch}
          easing={"enter"}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              display: "block",
            },
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
            textAlign: "center",
            pb: 2,
            backgroundColor: `${project?.portal.primaryColor ? project?.portal.primaryColor : "#00142a"}`,
          })}
        >
          <FormControl sx={{ width: "100%", px: 2 }}>
            <Autocomplete
              key={key}
              options={searchValues}
              sx={{ minWidth: { xs: "200px", sm: "100%" }, width: "auto", mr: { xs: 0.5, sm: 1.5 }, mt: 1.5, mb: 1.5 }}
              open={searchOpen}
              onOpen={() => {
                setSearchOpen(true);
              }}
              onClose={() => {
                setSearchOpen(false);
              }}
              freeSolo
              filterOptions={(x) => x}
              PaperComponent={CustomPaper}
              ListboxProps={{ style: { maxHeight: "calc(100vh - 100px)" } }}
              onInputChange={(e, value) => handleSearch(e, value)}
              onChange={handleAdvanceSearch}
              getOptionLabel={(option: any) => `${option.fullName} ${option.email}`}
              loading={searchLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              renderOption={(props: any, option: any) => {
                return (
                  <Box key={option.registrantId} sx={{ borderBottom: "1px solid #000" }}>
                    <Box sx={{ p: 1.5 }}>
                      <Box>
                        {option.fullName} {option.email ? `(${option.email})` : null}
                      </Box>
                      {option.primaryPhone ? <Box sx={{ fontSize: "10px" }}>{option.primaryPhone}</Box> : null}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1.5 }}>
                      <Box sx={{ display: "flex" }}>
                        {option.registrantIds.map((registrant: any, index: number) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                fontSize: "10px",
                                p: 0.5,
                                backgroundColor: theme.palette.secondary.main,
                                color: "#fff",
                                mr: 1.5,
                                borderRadius: 2,
                              }}
                              onClick={() => handleNavigate(registrant._id, registrant.project._id)}
                            >
                              {registrant.project.name}
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              }}
            />
          </FormControl>
        </Collapse>
      ) : null}
      {showMenu ? (
        <Collapse
          in={showMenu}
          easing={"enter"}
          sx={(theme) => ({
            textAlign: "center",
            pb: 2,
            backgroundColor: `${project?.portal.primaryColor ? project?.portal.primaryColor : "#00142a"}`,
          })}
        >
          <Box sx={{ width: { xs: "100%" }, display: "flex", justifyContent: { xs: "space-evenly", md: "flex-end" } }}>
            {/* {auth?.type !== "Developer" ? <NotificationBell /> : null} */}
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? <RegistrantNotification /> : null}
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? (
              <IconButton
                onClick={() => {
                  setShowMenu(!showMenu);
                  navigate(`/dashboard/notifications`);
                }}
              >
                <PermPhoneMsgIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
            {auth?.type !== "Developer" && auth?.type !== "Marketing" ? (
              <IconButton
                onClick={() => {
                  setShowMenu(!showMenu);
                  navigate(`/dashboard/sms`);
                }}
              >
                <TextsmsIcon sx={{ color: "#fff" }} />
              </IconButton>
            ) : null}
            <IconButton
              onClick={() => {
                setShowMenu(!showMenu);
                navigate(`/dashboard/scheduled-mailings`);
              }}
              aria-label="close"
            >
              <ForwardToInboxIcon sx={{ color: "#fff" }} />
            </IconButton>
            {auth?.type !== "Marketing" ? (
              <IconButton
                onClick={() => {
                  setShowMenu(!showMenu);
                  navigate(`/dashboard/appointments`);
                }}
              >
                <CalendarMonthIcon sx={{ color: `#fff` }} />
              </IconButton>
            ) : null}
            {project && projectid && auth?.type !== "Marketing" ? (
              <IconButton
                onClick={() => {
                  navigate(`/dashboard/project/${projectid}/list/came-ins-daily?row=15&page=1`);
                }}
              >
                <DirectionsWalkIcon sx={{ color: `#fff` }} />
              </IconButton>
            ) : null}
          </Box>
        </Collapse>
      ) : null}
    </AppBar>
  );
};

/* Components */
/* Types */

interface ISearchValues {
  fullName: string;
  email: string;
  primaryPhone: string;
  registrantIds: IRegistrantProject[];
}

interface IRegistrantProject {
  _id: string;
  project: IProject;
}

/* GQL */

const GETREGISTRANTS = gql`
  query searchRegistrants($search: String!) {
    searchRegistrants(search: $search) {
      fullName
      email
      primaryPhone
      registrantIds {
        _id
        project {
          _id
          name
        }
      }
    }
  }
`;
const CREATEDREGISTRANT = gql`
  subscription createdRegistrant($projectId: String!) {
    createdRegistrant(projectId: $projectId) {
      registrant {
        _id
        firstName
        lastName
        email
        primaryPhone
        project {
          _id
          portal {
            primaryColor
          }
        }
      }
      appointment {
        date
      }
    }
  }
`;

export default Header;
