import { Dispatch, useState, SetStateAction, useRef, useEffect, useContext } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { Box, TextField, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import SendIcon from "@mui/icons-material/Send";
import { convertAllDates, handleTextReplacement } from "../../utils/function";
import { IText } from "../../types/callRail";
import { useAppDispatch } from "../../app/hooks";
import { showErrorSnackbar, showSuccessSnackbar } from "../../features/snackbar/snackbarSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { IProjectAccess } from "../../types/user";
import { IRegistrant } from "../../types/registrant";
import { RegistrantNotificationContext } from "../../context/RegistrantNotificationContext";
import { selectProcess } from "../../features/registrants/registrantsSlice";

const Texts = (props: ChildProps) => {
  const user = useSelector(selectUser);
  const process = useSelector(selectProcess);
  const storeDispatch = useAppDispatch();
  const { registrantsDispatch } = useContext(RegistrantNotificationContext);
  const { data, handleTexts, handleCompleteStep, step, dateCompleted, handleProcess, setShowStep, registrant } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [text, setText] = useState<string>("");
  const open = Boolean(anchorEl);
  const divRef: any = useRef();

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollIntoView({ block: "end", inline: "end" });
    }
  }, [divRef, data]);

  const [textRegistrant, { loading }] = useLazyQuery(TEXTREGISTRANT, {
    onCompleted: (data) => {
      setText("");
      handleTexts(data.textRegistrant);
      if (step && setShowStep) {
        handleCompleteStep(
          {
            user: {
              _id: user?._id,
            },
            completed: dateCompleted,
            _id: step,
          },
          process
        );
        if (registrant) {
          registrantsDispatch({
            type: "DELETE",
            payload: {
              _id: registrant._id,
            },
          });
        }
        storeDispatch(showSuccessSnackbar("Text Message Sent!"));
        handleProcess("", null, false);
        setShowStep("");
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const submitText = (e: any, data: IText) => {
    if (!data && (e.key === "Enter" || e.type === "click") && !e.shiftKey) {
      if (!registrant?.primaryPhone) return storeDispatch(showErrorSnackbar("This registrant does not have a phone number"));
      let phone = registrant?.primaryPhone.replace(/\D/g, "");
      if (phone?.length < 10 && phone?.length > 11) {
        return storeDispatch(showErrorSnackbar("Only US/Canadian Phone Numbers are allowed"));
      }

      let projectId = user?.projectAccess.find((project: IProjectAccess) => project.project.name === registrant.project.name);
      if (!text) return storeDispatch(showErrorSnackbar("No Message Found"));

      textRegistrant({ variables: { project: projectId?.project._id, customerPhone: parseInt(phone, 10), message: text } });
      e.preventDefault();
    } else if ((e.key === "Enter" || e.type === "click") && !e.shiftKey) {
      if (registrant && !registrant?.primaryPhone) return storeDispatch(showErrorSnackbar("This registrant does not have a phone number"));
      let phone = data.phoneNumber.replace(/\D/g, "");
      if (phone.length < 10 && phone.length > 11) {
        return storeDispatch(showErrorSnackbar("Only US/Canadian Phone Numbers are allowed"));
      }

      let projectId = user?.projectAccess.find((project: IProjectAccess) => project.project.name === data.name);
      if (!text) return storeDispatch(showErrorSnackbar("No Message Found"));

      textRegistrant({ variables: { project: projectId?.project._id, customerPhone: parseInt(phone, 10), message: text } });
      e.preventDefault();
    }
  };

  const handleSmsReplacement = async (sms: string) => {
    let smsReplacement = await handleTextReplacement(sms, registrant!, user!, registrant?.project!);
    setText(smsReplacement);
    setAnchorEl(null);
  };

  return (
    <>
      {!registrant ? (
        <Box sx={{ backgroundColor: "#fff", p: 1, border: "1px solid #000" }}>
          <Typography variant="h3">
            <strong>
              {data.name} {data.messages.length && data.messages[0].registrant ? `- ${data.messages[0].registrant.fullName}` : null}
            </strong>
          </Typography>
        </Box>
      ) : null}
      <Box sx={{ p: 2, border: "1px solid #000", backgroundColor: "#c8ddf1", maxHeight: "500px", overflowY: "auto", height: "100%" }}>
        {data ? (
          <ChatContainer>
            {data.messages.map((messageData: any, index: number) => {
              if (messageData.direction === "outgoing") {
                return (
                  <MessageSent key={index}>
                    <Typography sx={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: messageData.content }}></Typography>
                    <Box sx={{ mt: 1, fontSize: "9px", textAlign: "right" }}>
                      {messageData.user ? messageData.user.fullName : data.name} - {convertAllDates(messageData.created_at, "Pp")}
                    </Box>
                  </MessageSent>
                );
              } else {
                return (
                  <MessageReceived key={index}>
                    <Typography sx={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: messageData.content }}></Typography>
                    <Box
                      sx={{ mt: 1, fontSize: "9px", cursor: "pointer", fontWeight: messageData.registrant ? 600 : 500 }}
                      onClick={() =>
                        messageData.registrant
                          ? window.open(
                              `/dashboard/project/${messageData.registrant?.project._id}/registrant/${messageData.registrant._id}`,
                              "_blank",
                              "rel=noopener noreferrer"
                            )
                          : null
                      }
                    >
                      <Box>
                        {messageData.registrant ? messageData.registrant.fullName : data.name} ({data.phoneNumber ? data.phoneNumber : ""})
                        - {convertAllDates(messageData.created_at, "Pp")}
                      </Box>
                    </Box>
                  </MessageReceived>
                );
              }
            })}
            <Box sx={{ mt: "auto", position: "relative" }}>
              <TextField
                aria-label="minimum height"
                multiline
                minRows={4}
                onKeyDown={(e) => submitText(e, data)}
                onChange={(e) => setText(e.target.value)}
                value={text}
                placeholder={
                  (registrant && !registrant.subscribed) || (data.messages[0].registrant && !data.messages[0].registrant.subscribed)
                    ? "Registrant is unsubscribed"
                    : `Reply to ${data.phoneNumber}`
                }
                disabled={loading || (registrant && !registrant.subscribed)}
                sx={{
                  width: "100%",
                  mt: 2,
                  mb: data.messages.length > 4 ? 2 : 0,
                  backgroundColor: "#fff",
                  opacity: loading ? 0.5 : 1,
                  borderRadius: "8px",
                  "& .MuiInputBase-root": {
                    px: 1,
                    pt: 1,
                    pb: 3,
                  },
                }}
              />
              <IconButton
                onClick={(event) => setAnchorEl(event?.currentTarget!)}
                sx={{ color: "#f6b849", cursor: "pointer", position: "absolute", top: 15, right: 5 }}
              >
                <BookmarkIcon />
              </IconButton>
              <IconButton
                onClick={(e) => submitText(e, data)}
                sx={{ color: "#00142a;", cursor: "pointer", position: "absolute", bottom: 15, right: 5 }}
              >
                <SendIcon />
              </IconButton>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {user?.texts.length ? (
                  user?.texts.map((comment: any, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleSmsReplacement(comment.text);
                      }}
                    >
                      <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "500px" }}>
                        {comment.name}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <Box sx={{ px: 1 }}>
                    You currently have no templates. Please click{" "}
                    <Link to={`/user`} style={{ textDecoration: "none", color: "#000" }}>
                      <strong>here</strong>
                    </Link>{" "}
                    to add one
                  </Box>
                )}
              </Menu>
            </Box>
            <div id={data.phoneNumber} ref={divRef}></div>
          </ChatContainer>
        ) : (
          <Box sx={{ textAlign: "center" }}>
            <em>No conversation has been started.</em>
            <Box sx={{ mt: 2, position: "relative" }}>
              <TextField
                aria-label="minimum height"
                multiline
                minRows={4}
                onKeyDown={(e) => submitText(e, data)}
                onChange={(e) => setText(e.target.value)}
                value={text}
                placeholder={registrant && !registrant.subscribed ? "Registrant is unsubscribed" : `Send a text message`}
                disabled={loading || (registrant && !registrant.subscribed)}
                sx={{
                  width: "100%",
                  mb: 2,
                  backgroundColor: "#fff",
                  opacity: loading ? 0.5 : 1,
                  borderRadius: "8px",
                  "& .MuiInputBase-root": {
                    px: 1,
                    pt: 1,
                    pb: 3,
                  },
                }}
              />
              <IconButton
                onClick={(event) => setAnchorEl(event?.currentTarget!)}
                sx={{ color: "#f6b849", cursor: "pointer", position: "absolute", bottom: 15, right: 5 }}
              >
                <BookmarkIcon />
              </IconButton>
              <IconButton
                onClick={(e) => submitText(e, data)}
                sx={{ color: "#00142a;", cursor: "pointer", position: "absolute", top: 5, right: 5 }}
              >
                <SendIcon />
              </IconButton>
              <Menu
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {user?.texts.length ? (
                  user?.texts.map((comment: any, index: number) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleSmsReplacement(comment.text);
                      }}
                    >
                      <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "500px" }}>
                        {comment.name}
                      </div>
                    </MenuItem>
                  ))
                ) : (
                  <Box sx={{ p: 1 }}>
                    You currently have no templates. Please click{" "}
                    <Link to={`/user`} style={{ textDecoration: "none", color: "#000" }}>
                      <strong>here</strong>
                    </Link>{" "}
                    to add one
                  </Box>
                )}
              </Menu>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

interface ChildProps {
  data: any;
  handleTexts: Dispatch<SetStateAction<any>>;
  handleCompleteStep?: any;
  dateCompleted?: Date | null;
  handleProcess?: any;
  step?: string;
  setShowStep?: Dispatch<SetStateAction<string>>;
  registrant?: IRegistrant;
}

export const ChatContainer = styled(Box)`
  --rad: 20px;
  --rad-sm: 3px;
  font: 16px/1.5 sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const MessageSent = styled(Box)`
  position: relative;
  max-width: 75%;
  padding: 7px 15px;
  margin-bottom: 2px;
  border-radius: var(--rad) var(--rad-sm) var(--rad-sm) var(--rad);
  background: #00142a;
  color: #fff;
  /* moves it to the right */
  margin-left: auto;
  border-top-right-radius: var(--rad);
  margin-top: 10px;
`;

export const MessageReceived = styled(Box)`
  position: relative;
  max-width: 75%;
  padding: 7px 15px;
  margin-bottom: 2px;
  border-radius: var(--rad-sm) var(--rad) var(--rad) var(--rad-sm);
  background: #f1f1f1;
  color: #555;
  /* moves it to the left */
  margin-right: auto;
  border-top-left-radius: var(--rad);
  margin-top: 10px;
`;

const TEXTREGISTRANT = gql`
  query textRegistrant($project: MongoID!, $customerPhone: Float!, $message: String!) {
    textRegistrant(project: $project, customerPhone: $customerPhone, message: $message) {
      lastMessage
      phoneNumber
      messages {
        direction
        content
        created_at
      }
      name
    }
  }
`;

export default Texts;
